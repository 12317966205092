<template>
    <div class="body">
        <div class="container">
            <div class="login_box">
                <div class="title">
                    <img src="../assets/img/carrot_logo_main.png" alt="캐럿로고">
                    <p>Please select the system to use.</p>
                </div>
                <div class="btn_box">
                    <div class="btn btn_1" v-if=" gate.auth_ctm=='Y'">
                        <h2 @click="gate.openUrl(`https://ctm.carrotenglish.kr/act/outlogin?email=${gate.email}`)">CTM<span>CARROT Teaching Management system</span></h2>
                    </div>
                    <div class="btn btn_2" v-if=" gate.auth_itm=='Y'">
                        <h2 @click="gate.openUrl(`https://hq.carrotenglish.net/auth/common/directLink?uid=${gate.email}`)">ITM<span>In-house Teaching Management system</span></h2>
                    </div>
                    <div class="btn btn_1" v-if=" gate.auth_mango=='Y'">
                      <h2 @click="gate.openUrl(`https://admin-hq.mangotalk.co.kr/login?email=${gate.email}&idx=${gate.idx}`)">MangoTalk</h2>
                    </div>
                    <div class="btn btn_1" v-if=" gate.auth_bpo=='Y'">
                      <h2 @click="gate.openUrl(`https://samsungbpo.com/carrot/login?email=${gate.email}`)">삼성전자DS BPO 관리자(캐럿)</h2>
                    </div>
                </div>
            </div>
            <footer>Copyright ⓒCARROT Inc. All rights reserved.</footer>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import {onMounted, reactive} from 'vue'
import { useRouter } from 'vue-router';
import axios from '@/plugins/axios.js';

export default {
  components: {
  },
  setup() {
    const router = useRouter();
    const gate = reactive({
      uid : "",
      upw : "",
      check:'N',
      auth_itm:"N",
      auth_ctm:"N",
      auth_mango:"N",
      auth_bpo:"N",
      email:"",
      idx:"0",

      openUrl:(url) =>{
        window.open(url);
      },
      checkData:()=>{
        let params = { };
        axios.get("/rest/mypims/gate_check", { params: params }).then((res) => {
          if( res.data.err == 0 ){
            if(res.data.idx_position!=6150){
              router.push({
                name: 'gate_login'
              });
            }
            gate.auth_itm= res.data.auth_itm;
            gate.auth_ctm= res.data.auth_ctm;
            gate.auth_mango= res.data.auth_mango;
            gate.auth_bpo= res.data.auth_bpo;
            gate.email= res.data.email;
            gate.idx= res.data.idx;

          } else {
            router.push({
              name: 'gate_login'
            });
          }
        });
      },
    });
    onMounted(() => {
      // Mounted
      gate.checkData();


    });

    return {gate};
  }
}
</script>


<style lang="scss" scoped>
    .body {
    background-image: url(../assets/img/bg_gate.jpg);
    font-weight: 400;
    font-size: 16px;
    font-family: 'Noto Sans KR', sans-serif;
    color: #282828;
    }

    .container {
        width: 717px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        flex-direction: column;
    }

    .login_box {
        width: 697px;
        background-color: #fff;
        border-radius: 28px;
        padding: 0 66px 76px;
        box-shadow: 0px 7px 28px rgba(102, 2, 15, 0.08);
        margin: 162px 10px;
        .title {
            text-align: center;
            img {
                padding: 84px 0 18px 0; 
            }
            p {
                padding: 0 0 8px 0;
            }
        }
    }

    .btn_box {
        .btn {
            cursor: pointer;
            width: 100%;
            border: 2px solid transparent;
            border-radius:12px; 
            background-image: linear-gradient(#fff, #fff), linear-gradient(149deg, #ff994e, #ff4068); 
            background-origin: border-box; 
            background-clip: content-box, border-box;
            text-align: center;
            margin: 44px 0 0 0;
            box-shadow: 0px 7px 25px rgba(255, 64, 105, 0.14);
            h2 {
                padding: 24px 0;
                font-size: 34px;
                font-weight: 700;
                span {
                    font-size: 17px;
                    font-weight: 400;
                    padding: 0 0 0 12px;
                }
            }
            &.btn_2 {
                background-image: linear-gradient(#fff, #fff), linear-gradient(149deg, #16d648, #478cff);
                box-shadow: 0px 7px 25px rgba(134, 128, 246, 0.21);
            }
        }
    }

    footer {
        padding: 0 0 15px 0;
        font-size: 14px;
    }
</style>